import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThaiDateAbbrPipe } from './thai-date-abbr.pipe';
import { ThaiDateFullPipe } from './thai-date-full.pipe';
import { ThaiDatePipe } from './thai-date.pipe';
import { OnlyNumber } from './onlynumber.directive';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ThaiDatePipe,
    ThaiDateAbbrPipe,
    ThaiDateFullPipe,
    OnlyNumber
  ],
  exports: [
    ThaiDatePipe,
    ThaiDateAbbrPipe,
    ThaiDateFullPipe,
    OnlyNumber
  ]
})
export class HelperModule { }
