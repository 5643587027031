<ol class="breadcrumb breadcrumb-arrow font-trirong">
  <li><a href="javascript://" [routerLink]="['/main']">Home</a></li>
  <li><a href="javascript://">About Us</a></li>
</ol>

<div class="text-center">
  <img width="300" alt="Angular Logo" src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTAgMjUwIj4KICAgIDxwYXRoIGZpbGw9IiNERDAwMzEiIGQ9Ik0xMjUgMzBMMzEuOSA2My4ybDE0LjIgMTIzLjFMMTI1IDIzMGw3OC45LTQzLjcgMTQuMi0xMjMuMXoiIC8+CiAgICA8cGF0aCBmaWxsPSIjQzMwMDJGIiBkPSJNMTI1IDMwdjIyLjItLjFWMjMwbDc4LjktNDMuNyAxNC4yLTEyMy4xTDEyNSAzMHoiIC8+CiAgICA8cGF0aCAgZmlsbD0iI0ZGRkZGRiIgZD0iTTEyNSA1Mi4xTDY2LjggMTgyLjZoMjEuN2wxMS43LTI5LjJoNDkuNGwxMS43IDI5LjJIMTgzTDEyNSA1Mi4xem0xNyA4My4zaC0zNGwxNy00MC45IDE3IDQwLjl6IiAvPgogIDwvc3ZnPg==">
  <h1 style="color: rgb(30, 122, 228)">
    <a href="https://angular.io/" target="_blank">Angular {{ version.full }} </a>
    and <a href="https://clarity.design" target="_blank">Clarity Design System 6.0.2</a>
  </h1>
  <strong>UX guidelines, HTML/CSS framework, and Angular components working together to craft exceptional experiences</strong>
</div>
