 


<section style="background-color: black;" *ngIf="login">
  <div class="background">
    <div class="shape"></div>
    <div class="shape"></div> 
  </div>
  <form method="post" #formData="ngForm">
    <h4> <b><i>लॉग इन</i></b>
     </h4>
  
    <label for="username">ई-मेल / फो. नं.</label>
    <input name="username" type="text" id="login_username" ngModel placeholder="ई-मेल / फो. नं."  >
  
    <label for="password">पासवर्ड</label>
    <input name="password" type="password" id="login_password" ngModel placeholder="पासवर्ड">
  
    <button style="    margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;" type="submit"   (click)="checkLogin(formData)">लॉग इन</button>
   
   <div style="text-align: center; margin-top: 20px;">
    <button (click)="registration()" 
    
    style="    margin-top: 50px;
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer; text-decoration: underline;"
    >नोंदणी साठी इथे क्लिक करा</button>
  </div>
   <div style="text-align: center; margin-top: 20px;">
    <button  (click)="registration1()"
    
    style="    margin-top: 50px;
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer; text-decoration: underline;"
    >उमेदवार नोंदणी साठी इथे क्लिक करा</button>
  </div>


  <!-- <ul>
    <li>Enroll Your self For Competition.</li>
    <li>Login With Email And Passward As You Entered At The Time Of registration.</li>
    <li>Select 10 Generics Names Of Your Choice From The Button <span style="color: rgb(0, 236, 12);">"Select Your Generics"</span></li>
    <li>Search Product Details From Internet Or Diff. Website And Enter it In Product Form</li>
  </ul>
  <marquee   direction="left"><b>Registration Open Till 4/10/2024 11:59 PM //Compitition Start Date 05/10/2024 12:00 AM And Ends On 15/10/2024 12:00 AM</b></marquee>

  <span style="color: red;">Project By Quality Excellance & Research Pune Sponsered By <a href="https://cyclonepharma.com/" target="_blank">Cyclone Pharma Pune</a>   Contact : 8087500550</span> -->

    
  </form>

</section>
<section style="background-color: black;" *ngIf="!login">
  <div class="background">
    <div class="shape"></div>
    <div class="shape"></div> 
  </div>

  <form method="post" #RegiData="ngForm">

    <h4 *ngIf="!isReg"> <b><i>कार्यकर्ता नोंदणी</i></b></h4>
    <h4 *ngIf="isReg"> <b><i>उमेदवाराचे नोंदणी</i></b></h4>

    <label *ngIf="!isReg" for="firstname">कार्यकर्त्याचे नाव</label>
    <label *ngIf="isReg" for="firstname">उमेदवाराचे नाव</label>
    <input name="firstname" type="text" id="firstname" ngModel    required>
  
    <label for="emp_email">ई-मेल</label>
    <input name="emp_email" type="email" id="emp_email" ngModel   placeholder="ई-मेल">
  
    <label for="contact_no">फो. नं.</label>
    <input name="contact_no" type="text" id="contact_no" ngModel required placeholder="फो. नं.">

    <label *ngIf="isReg" for="contact_no">पक्ष (Party)</label>
    <input *ngIf="isReg" name="Party" type="text" id="Party" ngModel required placeholder="पक्ष">

    <label   for="candId">Candidate</label>
    <select  name="candId"  ngModel required   >
      <option></option>
      <option style="color: black;" *ngFor="let item of candidateData;" value="{{item.candId}}">{{item.firstname}}</option>
    </select>

    <label for="password">पासवर्ड</label>
    <input name="password" type="password" id="login_password" ngModel placeholder="पासवर्ड">

 
  
    <button *ngIf="!isReg" style="margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;" type="submit"  (click)="registrationStudent(RegiData)">कार्यकर्ता नोंदणी करा</button>
    <button *ngIf="isReg" style="margin-top: 50px;
    width: 100%;
    background-color: #ffffff;
    color: #080710;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;" type="submit"  (click)="registrationStudent1(RegiData)">उमेदवार नोंदणी करा</button>
   
   
    <button (click)="registration()" 
    
    style="    margin-top: 50px;
    width: 100%;
    background-color: #000000;
    color: #ffffff;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer; text-decoration: underline;"
    >लॉग इन साठी इथे क्लिक करा</button>
  
    
  </form>
</section> 