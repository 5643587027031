import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from 'src/app/data-access.service';
declare let alertify;
@Component({
  selector: 'app-software-forms',
  templateUrl: './software-forms.component.html',
  styleUrls: ['./software-forms.component.scss']
})

export class SoftwareFormsComponent implements OnInit {
  
enquirydetails;
isView = false;

constructor(private service: DataAccessService, private router: Router) { }

ngOnInit(): void {
 this.getData();
}
selectedResult: any;


Pending = 'Pending';
Complete = 'Complete';


prod_lisy;
view(index){
this.selectedResult=this.enquirydetails[index];
// this.prod_lisy = this.selectedResult.product;
// console.log(this.prod_lisy);
this.isView = true;

}

getData() {
 this.service.get('enquiry.php?type=get_nutraGlobeorders').subscribe(response => {
   this.enquirydetails = response;
 });
}


}













