<div class="card" *ngIf="!isView">
    <div class="card-header" style="text-align: center">
      <div class="clr-row" style="margin-bottom: -10px;">
        <div class="clr-col-9" style="color: aliceblue;margin-top: -2px;text-align: left;">Online Order From Nutraglobe.in</div>
        <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
          <button class="btn btn-sm" style="background-color: red;color: white;" routerLink="/main/list-client">
            <clr-icon shape="window-close" class="is-solid"></clr-icon> CLOSE
          </button>
        </div>
      </div>
    </div>
    <div class="card-block">
      <div class="card-block">
        <div class="card-text">
          <div class="clr-row">
            <div class="table-container" style="overflow-x: auto;">
              <table class="table">
                <thead>
   
               <tr>
                  <td  style="font-size: 15px;   font-weight: bolder;">Sr. NO.</td>
                  <td  style="font-size: 15px; text-align: left; font-weight: bolder;">Customer Name</td>
                  <td  style="font-size: 15px; text-align: left; font-weight: bolder;">Contact No</td>
                  <td  style="font-size: 15px; text-align: left; font-weight: bolder; text-align: left">Email Id</td>
                  <td  style="font-size: 15px;   font-weight: bolder;">Amount</td>
                  <td  style="font-size: 15px; text-align: left; font-weight: bolder;">Payment Status</td>
                  <td  style="font-size: 15px;   font-weight: bolder;">Action</td>
              </tr>
            </thead>
            <hr><hr>
            <tbody>
              <tr scope="row" *ngFor="let item of enquirydetails ; let i = index">
                  <td style="text-align: center;">{{i+1}}</td>
                  <td style="text-align: left;">{{item.name}}</td>
                  <td style="text-align: left;">{{item.mobile}}</td>
                  <td style="text-align: left;">{{item.email}}</td>
                  <td style="text-align: center;">{{item.amount}}</td>
                  <td *ngIf="item.payment_status == 'complete'" style="text-align: center; color: green;"><b>{{item.payment_status}}</b></td>
                  <td *ngIf="item.payment_status != 'complete'" style="text-align: center; color: red;"><b>{{item.payment_status}}</b></td>
                  <td style="font-weight: bolder;"> 
                      <button class="btn btn-danger" (click)="view(i)">VIEW</button>
                  </td>
              </tr>
            </tbody>
          </table>
  
        </div>
  
      </div>
  
    </div>
  
  </div>
  </div>
  </div>
  
  <div class="card" *ngIf="isView">
    <div class="card-header" style="text-align: center">
      <div class="clr-row" style="margin-bottom: -10px;">
        <div class="clr-col-9" style="color: aliceblue;margin-top: -2px;text-align: left;">Customer Log</div>
        <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
          <button class="btn btn-sm" style="background-color: red;color: white;" (click)="isView=false">
            <clr-icon shape="window-close" class="is-solid"></clr-icon> CLOSE
          </button>
        </div>
      </div>
    </div>
    <div class="card-block">
  
      <div class="card-text">
  
        <div class="clr-row">
  
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12" >
            <label  >Customer Name :<span style="color:red;">*</span></label>
             <input type="text" [(ngModel)]="selectedResult['name']" readonly>
          </div>
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Contact No:<span style="color:red;">*</span></label>
            <input type="text" [(ngModel)]="selectedResult['mobile']" readonly>
          </div>
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Email Id:<span style="color:red;">*</span></label>
            <input type="text" [(ngModel)]="selectedResult['email']" readonly>
          </div>
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Payment Id:<span style="color:red;">*</span></label>
            <input type="text" [(ngModel)]="selectedResult['payment_id']" readonly>
          </div>
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label> Amount:<span style="color:red;">*</span></label>
            <input type="text" [(ngModel)]="selectedResult['amount']" readonly>
          </div>
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Payment Status:<span style="color:red;">*</span></label>
            <input *ngIf="selectedResult['payment_status'] == 'complete'" style="color: green; font-weight: bold;" type="text" [(ngModel)]="Complete" readonly>
            <input *ngIf="selectedResult['payment_status'] != 'complete'" style="color: red; font-weight: bold;"type="text" [(ngModel)]="Pending" readonly>
          </div>
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Email Id:<span style="color:red;">*</span></label>
            <input type="text" [(ngModel)]="selectedResult['email']" readonly>
          </div>
     
     
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Pin Code</label><br>
            <input type="text" [(ngModel)]="selectedResult['pincode']" readonly>
          </div>
          <div class="clr-col-lg-4 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Date & Time</label><br>
            <input type="text" [(ngModel)]="selectedResult['added_on']" readonly>
          </div>

          <div class="clr-col-lg-6 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Address</label><br>
            <textarea type="text" [(ngModel)]="selectedResult['address']" readonly></textarea>
          </div>

          <div class="clr-col-lg-6 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
            <label>Landmark</label><br>
            <textarea type="text" [(ngModel)]="selectedResult['address2']" readonly></textarea>
          </div>
         
  
        </div>    
        <div class="clr-row">
          <table class="table">
            <tr>
              <th>Name</th>
              <th>Code</th>
              <th>Quantity</th>
              </tr>
            <tr *ngFor="let product of selectedResult?.product | keyvalue">
              <td>{{ product.value.name  }}</td>
              <td>{{  product.value.code }}</td>
              <td>{{  product.value.quantity }}</td>
              
              </tr>
          </table>
        </div>
  
      </div>
    </div>
  
  
  </div>