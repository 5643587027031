import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from 'src/app/data-access.service';
declare let alertify;


@Component({
  selector: 'app-admindash',
  templateUrl: './admindash.component.html',
  styleUrls: ['./admindash.component.scss']
})
export class AdmindashComponent implements OnInit {

  isForm = false;
  isTable = true;
  generics;
  categories;
  packsizes;
  dosages;
  manufactures;
  isEdit =  false;
  isNewGeneric = 0;
  isNewManufacturer = 0;
  selectedResult=[];
  m_name = '';
  s_code = '';
  products1: any = [];
  distList;
  p_name='';
  p_cat='';
  d_form='';

 constructor(private service: DataAccessService, private router: Router) { }

 student_rank = 0;
 product_count = 0;
 student_name =  localStorage.getItem('username');

 username = '';

 ngOnInit(): void {
  this.checkLogin();
  this.username = localStorage.getItem('username');
  this.getMatdarData();
 
   this.totalParticipant();
  this.CurrentALLRankapi();
  this.totalProductCount();
  this.totalVote();
  }
 
  close(){
    this.isForm = false; this.getMatdarData(); this.isTable = true;

  }

  totalPat=0;
  zpGroup = '';
  voterList ;


  getMatdarData() {
    this.service.get('contest.php?type=getMatdarData1')
    .subscribe(response => {
      this.voterList = response;
     });
  }

  getDataByzpGroup() {
    this.service.get('contest.php?type=getMatdarData12&zpGroup='+this.zpGroup)
    .subscribe(response => {
      this.voterList = response;
     });
  }

 
  CurrentALLRankapi() {
    this.service.get('contest.php?type=CurrentAllRankapi')
    .subscribe(response => {
      this.rankData = response;
    
    });
  }


  rankData;


  totalParticipant() {
    this.service.get('contest.php?type=totalKaryakarte')
    .subscribe(response => {
      this.totalPat = response['totalPat'];
    });
  }

  totVote = 0
  totalVote() {
    this.service.get('contest.php?type=totalVote')
    .subscribe(response => {
      this.totVote = response['totalVote'];
    });
  }


  totalProductCount() {
    this.service.get('contest.php?type=totalProductCount')
    .subscribe(response => {
      this.totalProdCount = response['totalProductCount'];
    });
  }
  totalProdCount =0

  checkLogin() {
    this.service.validateLogin();
  }

  remCount = 0;
 
 

 

}













