import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from 'src/app/data-access.service';
declare let alertify;
 
@Component({
  selector: 'app-list-client',
  templateUrl: './list-client.component.html',
  styleUrls: ['./list-client.component.scss']
})
export class ListClientComponent implements OnInit {
  isForm = false;
  isTable = true;
  generics;
  categories;
  packsizes;
  dosages;
  manufactures;
  isEdit =  false;
  isNewGeneric = 0;
  isNewManufacturer = 0;
  selectedResult=[];
  m_name = '';
  s_code = '';
  products1: any = [];
  distList;
  p_name='';
  p_cat='';
  d_form='';

 constructor(private service: DataAccessService, private router: Router) { }

 student_rank = 0;
 product_count = 0;
 student_name =  localStorage.getItem('username');
 username = '';

 ngOnInit(): void {
  this.checkLogin();
  this.username = localStorage.getItem('username');
  this.totalParticipant();
  this.getMatdarData();
  }
 
  close(){
    this.isForm = false; this.getMatdarData(); this.isTable = true;

  }

  totalPat=0;
  voterList ;
  getMatdarData() {
    this.service.get('contest.php?type=getMatdarData')
    .subscribe(response => {
      this.voterList = response;
     });
  }

  
  zpGroup = '';


  getDataByzpGroup() {
    this.service.get('contest.php?type=getMatdarData12&zpGroup='+this.zpGroup)
    .subscribe(response => {
      this.voterList = response;
     });
  }


  totalParticipant() {
    this.service.get('contest.php?type=totalKaryakarte')
    .subscribe(response => {
      this.totalPat = response['totalPat'];
    });
  }


  checkLogin() {
    this.service.validateLogin();
  }

  remCount = 0;

 

  saveProduct(productData) {
    this.service.post('contest.php?type=saveKaryakarta', JSON.stringify(productData.value))
     .subscribe(response => {
       if (response['status'] === 'success') {
  
         this.totalParticipant();
         alert('Record saved successfully!');
         productData.reset();
       } else {
         alert('Please Try Again');
       }
       },
     (error: Response) => {
       if (error.status === 400) {
         alert('An error has occurred.');
       } else {
         alert('An error has occurred, http status:' + error.status);
       }
     });
   }

 

  isManu = false;


  checkData(value){
    if(value == 'Add If Not Found'){
        this.isManu = true;
        this.isForm = false;
    }
  }


 


 

}













