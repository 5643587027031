
<div class="card">
    <div class="card-header" style="text-align: center">
        <div class="clr-row" style="margin-bottom: -10px;">
            <div class="clr-col-9" style="color: aliceblue;margin-top: -2px;text-align: left;">Document Access
            </div>
            <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
                <button class="btn btn-sm" routerLink="/addnew" style="background-color: green;color: white;">
                    <clr-icon shape="window-close" class="is-solid"></clr-icon> ADD New Document
                </button>
                <button class="btn btn-sm" routerLink="/" style="background-color: red;color: white;">
                    <clr-icon shape="window-close" class="is-solid"></clr-icon> CLOSE
                </button>
            </div>
        </div>
    </div>
    <div class="card-block">
        <div class="card-text">
           <!-- <form #test="ngForm">
            <h3>Document Access:</h3><br>
           
            <div class="clr-row">
          
                <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
                    <label>Department:<span style="color: red;">*</span></label>
                    <input type="text" name="department" ngModel>
                </div> 

                <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">                   
                     <label>Document No:<span style="color: red;">*</span></label>
                    <input type="number" name="document_no" ngModel>
                </div> 

                <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">                    
                    <label>Name of Document:<span style="color: red;">*</span></label>
                    <input type="text" name="document_name" ngModel>
                </div> 

                
                 <div>
                 <button class="btn btn-sm" routerLink="/" style="background-color: rgb(0, 162, 255);color: white;"> Upload Document</button>

                   <input type="file" name="challan_file" (change)="onFileChanged($event)" accept="application/pdf" ngModel> 
              </div> 
           
         </div>
         </form> -->
        </div>
        <!-- <div class="card-footer">
            <button class="btn btn-primary" >Upload Document </button>
        </div> -->
        <div class="card-block" >
            <div class="card-text ">
                <table class="table" style="border: 1px solid #004A70;" >
       
                    <tr >
                      <th  style="font-size:15px;background-color:#004A70;color: aliceblue;" scope="col">Department</th>
                      <th  style="font-size:15px;background-color:#004A70;color: aliceblue;" scope="col">Department No.</th>
                      <th  style="font-size:15px;background-color:#004A70;color: aliceblue;" scope="col">Name of Document</th>
                      <th  style="font-size:15px;background-color:#004A70;color: aliceblue;" scope="col">Upload Date</th>
                    </tr>
                </table>
    
      </div>
      <div class="card-footer">
        <button class="btn btn-primary">Download</button>
    </div>
</div>
