import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { AuthGuardService } from './../../services/auth-guard.service';

import { MainPageComponent } from './main-page/main-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutComponent } from './about/about.component';
import { LayoutComponent } from './layout/layout.component';
  import { ListClientComponent } from './list-client/list-client.component';
import { SoftwareFormsComponent } from './software-forms/software-forms.component';
 import { EditProformaComponent } from './edit-proforma/edit-proforma.component';
  import { AdmindashComponent } from './admindash/admindash.component';
 

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    // canActivate: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'list-client', pathMatch: 'full' },
      { path: 'main', component: MainPageComponent },
       { path: 'about', component: AboutComponent },
      { path: 'list-client', component: ListClientComponent },
       { path: 'mappdata', component:  EditProformaComponent },
      { path: 'software_forms', component: SoftwareFormsComponent },
       { path: 'adminDash', component: AdmindashComponent },
 
      { path: '**', component: PageNotFoundComponent },
     ]
  },
  
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
