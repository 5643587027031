import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class DataAccessService {
  [x: string]: any;
   domain = "https://cpplgmp.com/admin/";
 // domain = "http://gmpserver.in/admin/";

  url = this.domain + 'nutra/';

  grades: any = [];
  observableGrade;  

  departments: any = [];
  observableDepartment;

  units: any = [];
  observableUnit;

  dosages: any = [];
  observableDosage;

  states: any = [];
  observableState;

  vendors: any = [];
  observableVendor;

  styles: any = [];
  observableStyle;

  storages: any = [];
  observableStorage;

  shelfs: any = [];
  observableShelf;

  plants: any = [];
  observablePlant;

  isMobile = false;

  username = '';
  observableUsername;
  constructor(private http: HttpClient, private router: Router) {
    this.username = localStorage.getItem('username');

    let width  = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (width < 850) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }

    this.observableGrade = new BehaviorSubject(this.grades);
    this.observableDepartment = new BehaviorSubject(this.departments);
    this.observableUnit = new BehaviorSubject(this.units);
    this.observableDosage = new BehaviorSubject(this.dosages);
    this.observableState = new BehaviorSubject(this.states);
    this.observableVendor = new BehaviorSubject(this.vendors);
    this.observableStyle = new BehaviorSubject(this.styles);
    this.observableStorage = new BehaviorSubject(this.storages);
    this.observableShelf = new BehaviorSubject(this.shelfs);
    // this.observablePlant = new BehaviorSubject(this.plants);

    this.observableUsername = new BehaviorSubject(this.username);
  }

  get(url) {
    url = this.url + url + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1'  + '&emp_id='+ localStorage.getItem('emp_id') + '&candId='+ localStorage.getItem('candId');
    return this.http.get(url);
  }
  getData(url) {
    
    return this.http.get(url);
  }

  post(url, postData) {
    url = this.url + url + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1'  + '&emp_id='+ localStorage.getItem('emp_id') + '&candId='+ localStorage.getItem('candId');
    return this.http.post(url, postData);
  }

  logout() {
    localStorage.clear();
    window.location.reload();
  }

  open(url) {
    window.open(this.url + url + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1'  + '&emp_id='+ localStorage.getItem('emp_id') + '&candId='+ localStorage.getItem('candId'));
  }

  getCommonDetails() {
    // let url = this.url + 'common.php?type=getCommonDetails' + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1';
    // this.http.get(url).subscribe(response => {
    //   this.grades = response['grades'];
    //   this.gradeChange();

    //   this.departments = response['departments'];
    //   this.departmentChange();

    //   this.units = response['units'];
    //   this.unitChange();

    //   this.dosages = response['dosages'];
    //   this.dosageChange();

    //   this.states = response['states'];
    //   this.stateChange();

    //   this.vendors = response['vendors'];
    //   this.vendorChange();

    //   this.styles = response['styles'];
    //   this.styleChange();

    //   this.storages = response['storages'];
    //   this.storageChange();

    //   this.shelfs = response['shelfs'];
    //   this.shelfChange();

    //   // this.plants = response['plants'];
    //   // this.plantChange();
    // });
  }

  gradeChange() {
    this.observableGrade.next(this.grades);
  }

  departmentChange() {
    this.observableDepartment.next(this.departments);
  }

  unitChange() {
    this.observableUnit.next(this.units);
  }

  dosageChange() {
    this.observableDosage.next(this.dosages);
  }

  stateChange() {
    this.observableState.next(this.states);
  }

  vendorChange() {
    this.observableVendor.next(this.vendors);
  }

  styleChange() {
    this.observableStyle.next(this.styles);
  }

  storageChange() {
    this.observableStorage.next(this.storages);
  }

  shelfChange() {
    this.observableShelf.next(this.shelfs);
  }

  userChange() {
    this.observableUsername.next(this.username);
  }

  // plantChange() {
  //   this.observablePlant.next(this.plants);
  // }

  getStorages() {
    let url = this.url + 'qa/master.php?type=getStorageConditions' + '&token=' + localStorage.getItem('token') + '&user_no=gmpdemo1';
    this.http.get(url).subscribe(response => {
      this.storages = response['storages'];
      this.storageChange();
    });
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }



  validateLogin() {
    const temp = {};
    if (localStorage.getItem('emp_id') !== null) {
      temp['emp_id'] = localStorage.getItem('emp_id');
       this.http.post(this.url + 'login.php?type=checkLoginKarykarata&epId='+localStorage.getItem('emp_id'), JSON.stringify(temp)).subscribe(response => {
        if (response['status'] === 'success') {
          
          if( localStorage.getItem('type') == 'Candi'){
            this.router.navigate(['/main/adminDash']);
          }else{
            this.router.navigate(['/main/list-client']);
          }
          
        } else {
          localStorage.clear();
          sessionStorage.clear();
          this.router.navigate(['login']);
        }
      });
    } else {
      this.router.navigate(['login']);
    }
  }
  

}
