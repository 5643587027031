
<div class="card">
    <div class="card-header" style="text-align: center">
        <div class="clr-row" style="margin-bottom: -10px;">
            <div class="clr-col-9" style="color: aliceblue;margin-top: -2px;text-align: left;">Add New Document
            </div>
            <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
                
                <button class="btn btn-sm" routerLink="/document" style="background-color: red;color: white;">
                    <clr-icon shape="window-close" class="is-solid"></clr-icon> CLOSE
                </button>
            </div>
        </div>
    </div>
    <div class="card-block">
        <div class="card-text">
           <form #test="ngForm">
            <h3>Document Access:</h3><br>
           
            <div class="clr-row">
          
                <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">                   
                    <label>Department:<span style="color: red;">*</span></label>
                    <select>
                    <input type="text" name="department" ngModel>
                    </select>
                </div> 

                <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">                   
                     <label>Document No:<span style="color: red;">*</span></label>
                    <input type="number" name="document_no" ngModel>
                </div> 

                <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">                    
                    <label>Name of Document:<span style="color: red;">*</span></label>
                    <input type="text" name="document_name" ngModel>
                </div> 

                
                <!-- <div>
                 <button class="btn btn-sm" routerLink="/" style="background-color: rgb(0, 162, 255);color: white;"> Upload Document</button>

                   <input type="file" name="challan_file" (change)="onFileChanged($event)" accept="application/pdf" ngModel> 
              </div> -->
           
         </div>
         </form>
        </div>
        <div class="card-footer">
            <form action="/action_page.php">
                <label for="myfile"><b>Document Upload:</b></label>
                <input type="file" id="myfile" name="myfile" multiple><br><br>
                <input class="btn btn-primary" type="submit" value="Upload Document">
              </form>
          
        </div>
      </div>
</div>
