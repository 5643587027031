<div class="card"  >
    <div class="card-header" style="text-align: center">
      <div class="clr-row" style="margin-bottom: -10px;">
        <div class="clr-col-9" style="color: aliceblue;margin-top: -2px;text-align: left;">Select Your Generics </div>
        <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
          <button class="btn btn-sm" style="background-color: red;color: white;" routerLink="/main/list-client">
            <clr-icon shape="window-close" class="is-solid"></clr-icon> CLOSE
          </button>
        </div>
      </div>
    </div>

    <div class="card-block">
      <div class="card-text">
        <h2>Select Your Generics </h2>
          <form #companyDetails="ngForm">
            <div class="clr-row">
 
              <div class="clr-col-lg-9 clr-col-md-9 clr-col-sm-12 clr-col-xs-12">
                <label>Select Your Generics Only  10 </label>
                <select name="generic" ngModel required >
                  <option></option>
                   <option *ngFor="let item of generics;" value="{{item.sr}}">{{item.value}}</option>
                </select>  
              </div>
  
              <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
                <br>
                <button class="btn btn-primary"  (click)="saveProforma(companyDetails)">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
   
    <table class="table table-border">
      <tr>
        <td style="background-color: aquamarine;text-align: left;"><b>Sr.No.</b></td>
        <td style="background-color: aquamarine;text-align: left;"><b>Generic Name</b></td>
        </tr>
      <tr *ngFor="let data of mappedGenerics;let i = index;">
        <td style="text-align: left; font-weight: bolder;">{{i+1}}</td>
        <td style="text-align: left; font-weight: bolder;">{{data.generic}}</td>
  
      </tr>
    </table>
  
  </div>
  
  