import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from 'src/app/data-access.service';
declare let alertify: any;

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit {
  myDate: Date;
  plants;
  isLogin = false;
  isreset = false;
  isNewReset = false;
  isError= false;
  loginData: any = [];
  new_password = '';
  confirm_password = '';
  constructor(public service: DataAccessService,  private router: Router) {
    setInterval(() => { this.myDate = new Date(); }, 1);
  }

  ngOnInit() {
    this.validateUser();
    this.getPlant();
    this.getCandidate();
  }

  candidateData;

  getCandidate() {
    this.service.get('withoutToken.php?type=getCandidate')
    .subscribe(response => {
      this.candidateData = response;
    });
  }

  getPlant() {
    // this.service.get('master/plant.php?type=getPlant&show_corporate=1').subscribe(response => {
    //   this.plants = response;
    // });
  }


  checkLogin(formData) {
    if (!formData.valid) {
      alertify.error('All fields are required!');
      return;
    }

    this.loginData = formData.value;
    const data = formData.value;
    data['plant_id']='P001';
    this.service.post('login.php?type=newLoginKarykarta&username=' + data['username'] + '&password=' + data["password"] + '&plant_id=' + data["plant_id"], JSON.stringify(data)).subscribe(response => {
   
     
      if (response['status'] === 'success') {
        
        
          console.log('hello');
          localStorage.setItem('token', response['token']);
          localStorage.setItem('plant_id', response['plant_id']);
          localStorage.setItem('is_corporate', response['is_corporate']);
          localStorage.setItem('department', response['department']);
          localStorage.setItem('designation', response['designation']);
          localStorage.setItem('user', response['user']);
          localStorage.setItem('checker', response['checker']);
          localStorage.setItem('approver', response['approver']);
          localStorage.setItem('username', response['username']);
          localStorage.setItem('emp_id', response['emp_id']);
          localStorage.setItem('candId', response['candId']);
          this.service.username = response['username'];
          this.service.userChange();
          localStorage.setItem('user_no', 'gmpdemo1');
          localStorage.setItem('type', response['type']);
          localStorage.setItem('company_name', 'GMP Software Pvt Ltd');
          localStorage.setItem('login', 'yes');
          localStorage.setItem('client_code', response['client_code']);
           this. getClientSoftwareInfo() ;
          this.isLogin = true;
          this.service.getCommonDetails();

          if( response['type'] == 'Candi'){
            this.router.navigate(['/main/adminDash']);
          }else{
            this.router.navigate(['main']);
          }



         
      }  else {
        // Login failed, show error message
        alertify.error(response['message'] || 'Invalid username or password');
        this.isLogin = false;
      }
    });
  }


  registrationStudent(formData) {
    if (!formData.valid) {
      alertify.error('All fields are required!');
      return;
    }

    this.loginData = formData.value;
    const data = formData.value;
    
    this.service.post('login.php?type=registrationKaryakarta', JSON.stringify(data)).subscribe(response => {
    
       if (response['status'] === 'success') {
        // Registration successful
        alertify.success('Registration Successful!');
        formData.reset();  // Reset the form after successful registration
        this.login = !this.login;  // Toggle login if needed
        this.isReg = false;
      } else if (response['status'] === 'error' && response['message']) {
        // Handle specific error messages from the backend
        alertify.error(response['message']);
      } else {
        // Generic error if no specific message is provided
        alertify.error('Registration failed! Please try again.');
      }
 
    });
  }


  registrationStudent1(formData) {
    if (!formData.valid) {
      alertify.error('All fields are required!');
      return;
    }

    this.loginData = formData.value;
    const data = formData.value;
    
    this.service.post('login.php?type=registrationCandi', JSON.stringify(data)).subscribe(response => {
    
       if (response['status'] === 'success') {
        // Registration successful
        alertify.success('Registration Successful!');
        formData.reset();  // Reset the form after successful registration
        this.login = !this.login;  // Toggle login if needed
        this.isReg = false;
      } else if (response['status'] === 'error' && response['message']) {
        // Handle specific error messages from the backend
        alertify.error(response['message']);
      } else {
        // Generic error if no specific message is provided
        alertify.error('Registration failed! Please try again.');
      }
 
    });
  }





  getClientSoftwareInfo() {
    // this.service.getData('http://trialapi.paperlessgmp.live/client/company.php?type=getCompanyByCode&company_code='+localStorage.getItem("client_code")).subscribe(response => {
    //   localStorage.setItem('client_info', JSON.stringify(response)); ;
    // });
  }





  validateUser() {
    if (localStorage.getItem('token') !== null && localStorage.getItem('department') !== null) {
      this.isLogin = true;
      this.service.getCommonDetails();
   
    } else {
      this.isLogin = false;
    }
  }

  reset(data) {
    if (!data.valid) {
      alertify.error('All fields are required!');
      return;
    }
    this.service.post('it/password.php?type=resetPassword', JSON.stringify(data.value)).subscribe(response => {
      if (response['status'] == 'success') {
        alertify.success('Password reset Request send for Approval!');
        this.isreset = false;
        data.reset();
      } else {
        alertify.error('Failed: An error occured, please try again!');
      }
    });
  }

  resetPassword(data) {
    if (!data.valid) {
      alertify.error('All fields are required!');
      return;
    }
    let temp = data.value;

    if (temp['new_password'] !== temp['confirm_password']) {
      alertify.error('Invalid Confirm Password!');
      return;
    }

    temp['emp_id'] = this.loginData['username'];
    this.service.post('checkLogin.php?type=resetPassword', JSON.stringify(temp)).subscribe(response => {
      if (response['status'] == 'success') {
        alertify.success('Password has been Updated!');
        this.isNewReset = false;
        data.reset();
      } else {
        alertify.error('Failed: An error occured, please try again!');
      }
    });
  }





  login = true;
  isReg = false;


  registration(){

    this.login = !this.login;
    this.isReg = false;
  }

  registration1(){
    this.login = !this.login;
    this.isReg = true;
  }















}
