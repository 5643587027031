import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';

import { MainRoutingModule } from './main-routing.module';
import { MainPageComponent } from './main-page/main-page.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AboutComponent } from './about/about.component';
import { LayoutComponent } from './layout/layout.component';
 import { HelperModule } from 'src/app/pipes/helpers.module';
 import { ListClientComponent } from './list-client/list-client.component';
import { SoftwareFormsComponent } from './software-forms/software-forms.component';
 import { EditProformaComponent } from './edit-proforma/edit-proforma.component';
 import { AdmindashComponent } from './admindash/admindash.component';
   
@NgModule({
  declarations: [
    MainPageComponent, PageNotFoundComponent,
    AboutComponent, LayoutComponent, ListClientComponent, SoftwareFormsComponent,
     EditProformaComponent, AdmindashComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ClarityModule,
    HelperModule,
    MainRoutingModule,
       
    
  ]
})
export class MainModule { }
