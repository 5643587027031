import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit {
  collapsed = true;

  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.username = localStorage.getItem('username');

  }
  username='';
  onLogout() {
    localStorage.clear();
    sessionStorage.clear();
    this.router.navigate(['login']);
  }

}
