import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataAccessService } from 'src/app/data-access.service';
declare let alertify;
@Component({
  selector: 'app-edit-proforma',
  templateUrl: './edit-proforma.component.html',
  styleUrls: ['./edit-proforma.component.scss']
})
export class EditProformaComponent implements OnInit {

  generics;
    


  constructor(private service: DataAccessService, private router: Router) { }

  ngOnInit(): void {
    this.getData();
    this.getGenericDetails();
  }
 
  getData() {

    this.service.get('contest.php?type=getGenericsOfStudentDetails').subscribe(response => {
      this.mappedGenerics = response;
    });
  }

  mappedGenerics;

  getGenericDetails() {

    this.service.get('contest.php?type=getGenericDetails').subscribe(response => {
      this.generics = response;
    });
  }
  
  
  saveProforma(data) {

    console.log(data.value);
    if (!data.valid) {
      alert('All fields are required');
      return;
    }
    let temp = data.value;   
   
    this.service.post('contest.php?type=saveGenerecForStudent', JSON.stringify(temp)).subscribe(response => {
      if (response['status'] == 'success') {
        alertify.success('Proforma save Successfuly');
        this.getData();
        this.getGenericDetails();
       
      } else {
        alert('Failed: An error occured, please try again!');
      }
    });
    console.log("down");
    data.resetForm();
     

  }

 
 


}













