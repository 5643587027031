<div class="clr-row">
  <marquee  direction="left"  >Total Votes By City (<b style="color: red;"> Parner : 320994</b> )  ,  (<b style="color: red;"> Shrigonda : 315595</b> )  ,  (<b style="color: red;"> Nagar : 283934</b> )  ,  (<b style="color: red;"> Shevgoan : 342123</b> )</marquee>


  
  <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
   <div class="contestant-card"> 
     <div class="name">टोटल कार्यकर्ते : {{totalPat}}</div>
     <!-- <div class="name">{{student_name}}</div>
     <div class="count">{{product_count}}</div> -->
   </div> 
  </div>
  <div class="clr-col-lg-6 clr-col-md-6 clr-col-sm-12 clr-col-xs-12">
   <div class="contestant-card"> 
     <div class="name">नमस्कार  {{student_name}}</div>
     <!-- <div class="name">{{student_name}}</div>
     <div class="count">{{product_count}}</div> -->
   </div>
  </div>
  <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
   <div class="contestant-card"> 
     <div class="name">तुमचा एन्ट्री  काउन्ट</div>
     <div class="count">{{product_count}}</div>
     <!-- <div class="name">{{student_name}}</div>
     <div class="count">{{product_count}}</div> -->
   </div>
  </div>

 
 <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12"></div>


 </div>
 

 <div class="container-fluid" *ngIf="isTable">
   <div class="card">
     <div class="card-header">
       <div class="clr-row" style="margin-bottom: -10px;">
        <div class="clr-col-lg-3" style="color: rgb(255, 255, 255);margin-top: -2px;text-align: left;">मतदार सूची</div>
        <div class="clr-col-lg-6" style="text-align: right;padding-right: 0px;margin-top: -7px;">
          शोधा झेड. पी. गटा नुसार 
          <select name="zpGroup" style="width: 20%;" [(ngModel)]="zpGroup" required  (change)="getDataByzpGroup()">
            <option></option>
            <option value="Takali">टाकळी ढोकेश्वर</option>
            <option value="Kanhur">कान्हूर पठार</option>
            <option value="Supa">सूपा गट</option>
            <option value="Nighoj">निघोज गट</option>
            <option value="Dhavalpuri">ढवळपुरी गट</option>
          </select> 
        </div>
          <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
           <button id="isAddNew" class="btn btn-danger btn-sm" (click)="isTable = false; isForm = true;">नवीन मतदार जोडा</button>
          </div>
       </div>
     </div>
     <div class="card-body"> 
   
   
       <div class="table-wrapper-scroll-y">
         <table class="table  table-border" style="margin-top: 10px;">
         
             <tr>
               <th style="background-color: aquamarine;font-weight: bolder;">अ. क्र.</th>
               <th style="background-color: aquamarine;font-weight: bolder; width: 15rem;text-align: left;">मतदाराचे नाव</th>
               <th style="background-color: aquamarine;font-weight: bolder; width: 6rem;text-align: left;">मतदाराचा फो. नंबर </th>
               <th style="background-color: aquamarine;font-weight: bolder; width: 7rem;text-align: left;">गावाचे नाव</th>
               <th style="background-color: aquamarine;font-weight: bolder; width: 7rem;text-align: left;">झेड. पी. गट:</th>
               </tr>
           
             <tr *ngFor="let list of voterList; let i = index">
               <td>{{ i+1 }}</td>
               <td style="text-align: left;">{{ list.name }}</td>
               <td style="text-align: left;">{{ list.phNo }}</td>
               <td style="text-align: left;">{{ list.villiage }}</td>
               <td style="text-align: left;">{{ list.zpGroup }}</td>
               </tr>
            
         </table>
       </div>
     </div>
   </div>
 </div>
 
 <div class="container-fluid" *ngIf="isForm">
   <div class="card">
      <div class="card-header">
       <div class="clr-row" style="margin-bottom: -10px;">
         <div class="clr-col-lg-9" style="color: rgb(255, 255, 255);margin-top: -2px;text-align: left;">नवीन मतदार फॉर्म</div>
         <div class="clr-col-lg-3" style="text-align: right;padding-right: 0px;margin-top: -7px;">
           <!-- <button  class="btn btn-success btn-sm"  routerLink="/main/mappdata">Select Generics To Add Product</button> -->
         </div>
       </div>
     </div>
     <div class="card-block">
       <form #productForm="ngForm" method="post" enctype="multipart/form-data">
         <div class="clr-row">
           <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
             <label>मतदाराचे नाव : <span style="color: red;"><b>*</b></span></label>
             <input type="text" ngModel name="name" class="form-control"  required>
           </div>
           <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
             <label>मतदाराचा फो. नंबर :</label>
             <input type="text" ngModel name="phNo" class="form-control"   >
           </div>
         
           <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
             <label>गावाचे नाव : <span style="color: red;"><b>*</b></span></label>
             <input type="text" ngModel name="villiage" class="form-control"  required>
           </div>
           <div class="clr-col-lg-3 clr-col-md-3 clr-col-sm-12 clr-col-xs-12">
             <label>झेड. पी. गट : <span style="color: red;"><b>*</b></span></label>
              <select name="zpGroup" ngModel required  >
                <option></option>
                <option value="Takali">टाकळी ढोकेश्वर</option>
                <option value="Kanhur">कान्हूर पठार</option>
                <option value="Supa">सूपा गट</option>
                <option value="Nighoj">निघोज गट</option>
                <option value="Dhavalpuri">ढवळपुरी गट</option>
              </select> 
           </div>
         </div>
       </form>
     </div>
     <div class="card-footer">
       <button type="submit" class="btn btn-sm btn-primary" (click)="productForm.form.valid && saveProduct(productForm)"
         [disabled]="productForm.form.invalid"><i class="fa fa-dot-circle-o"></i> Submit</button>&nbsp;&nbsp;
       <button type="submit"  class="btn btn-sm btn-danger" (click)="close()">Close</button>
     </div>
   </div>
 </div>
 
 

 
 
 
 