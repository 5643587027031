<clr-main-container>
  <clr-header class="header-5">
    <div class="branding">
      <a href="javascript://" class="nav-link">
        <clr-icon shape="vm"></clr-icon>
        <span class="title">विधान सभा २०२४</span>
      </a>
    </div>
    <div class="header-nav" [clr-nav-level]="1">
      <a href="javascript://" class="active nav-link nav-icon" routerLink="/">
        <clr-icon shape="home"></clr-icon>
      </a>
      <a href="javascript://" class="nav-link nav-icon">
        Welcome : {{username}}
      </a>
    </div>
    <!-- <form class="search">
      <label for="search_input">
        <input id="search_input" type="text" placeholder="Search for keywords...">
      </label>
    </form> -->
    <div class="header-actions">
      <clr-dropdown>
        <button class="nav-text" clrDropdownTrigger (click)="onLogout()">Log out</button>

        <!-- <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right"> -->
          <!-- <a href="javascript://" clrDropdownItem routerLink="/main/about">About</a>
          <a href="javascript://" clrDropdownItem routerLink="/main/preference">Preferences</a> -->
          <!-- <a href="javascript://" clrDropdownItem (click)="onLogout()">Log out</a> -->
        <!-- </clr-dropdown-menu> -->
      </clr-dropdown>
    </div>
  </clr-header>

  <div class="content-container">
    <main class="content-area">
      <router-outlet></router-outlet>
    </main>

    <clr-vertical-nav [clrVerticalNavCollapsible]="true" [(clrVerticalNavCollapsed)]="collapsed" [clr-nav-level]="2">
      
      
      <!-- <clr-vertical-nav-group routerLinkActive="active">
        <clr-icon shape="flame" clrVerticalNavIcon></clr-icon>
        Customer Registration
        <clr-vertical-nav-group-children>
          <a clrVerticalNavLink routerLink="enquiry-form" routerLinkActive="active">
            Customer Registration
          </a>
        
          <a clrVerticalNavLink routerLink="../tax_dash" routerLinkActive="active">
            Genarate Tax Invoice
          </a>
          <a clrVerticalNavLink routerLink="../proposal" routerLinkActive="active">
            Tax Invoice Log
          </a>
          <a clrVerticalNavLink routerLink="../log" routerLinkActive="active">
            Payment statement
          </a>
          <a clrVerticalNavLink routerLink="../proforma" routerLinkActive="active">
            Customer Log
          </a>
        </clr-vertical-nav-group-children>


      </clr-vertical-nav-group> -->
   
     
    </clr-vertical-nav>
  </div>

</clr-main-container>